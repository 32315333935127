import {trpc} from '@/api/trpcClient';
import {Switch} from '@headlessui/react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  Button,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  cn,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

export const getUpdateMerchantEmailNotificationsSchema = () =>
  z.object({
    sendMerchantPaymentEmailNotification: z.boolean(),
    notificationEmail: z.string().email(t`Email is not valid`),
  });

export type UpdateMerchantEmailNotificationsData = z.infer<
  ReturnType<typeof getUpdateMerchantEmailNotificationsSchema>
>;

export type UpdateMerchantEmailNotificationsProps = {
  merchantAccountRow: MerchantAccountDetailsOutput | MerchantAccountsListItem;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateMerchantEmailNotifications = ({
  merchantAccountRow,
  onClose,
  isOpen,
  triggerRefetch: refetchMerchantAccountsList,
}: UpdateMerchantEmailNotificationsProps) => {
  const {i18n} = useLingui();
  const brandConfiguration =
    'brandConfiguration' in merchantAccountRow
      ? merchantAccountRow.brandConfiguration
      : merchantAccountRow.merchantAccount?.brandConfiguration;
  const merchantAccountId =
    'brandConfiguration' in merchantAccountRow
      ? merchantAccountRow.id
      : merchantAccountRow.merchantAccount?.id;

  const updateMerchantEmailNotificationsForm = useForm<UpdateMerchantEmailNotificationsData>({
    resolver: zodResolver(getUpdateMerchantEmailNotificationsSchema()),
    defaultValues: {
      notificationEmail: brandConfiguration?.notificationEmail ?? undefined,
      sendMerchantPaymentEmailNotification:
        brandConfiguration?.sendMerchantPaymentEmailNotification,
    },
  });
  const {
    handleSubmit,
    register,
    formState: {errors},
    setValue,
    watch,
  } = updateMerchantEmailNotificationsForm;
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const watchSendMerchantPaymentEmailNotification = watch('sendMerchantPaymentEmailNotification');

  const updateMerchantMutation = trpc.merchantAccount.editMerchantAccountBranding.useMutation({
    onSuccess: () => {
      refetchMerchantAccountsList();
      onClose();
      showSuccessNotification(
        t`Merchant information updated!`,
        t`You have successfully updated merchant information.`
      );
    },
    onError: error => {
      showErrorNotification(t`Error`, error.message);
    },
  });

  const onUpdateMerchantSubmit = useCallback(
    (data: UpdateMerchantEmailNotificationsData) => {
      if (!merchantAccountId) {
        return;
      }
      updateMerchantMutation.mutate({
        merchantAccountId: merchantAccountId,
        ...data,
      });
      return;
    },
    [merchantAccountId]
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Email Notification Options')}
      text={i18n._(
        'Customers automatically receive transaction emails. Enable these settings to send copies to company as well.'
      )}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSubmit)}
              isLoading={updateMerchantMutation.isLoading}
            >
              <Trans>Update Merchant</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSubmit)}>
        <div>
          <div className="flex flex-col gap-3">
            <Label className="pb-3" text={i18n._('Notification Email')}>
              <InputText {...register('notificationEmail')} placeholder="example@mail.com" />
              <ValidationError isVisible={Boolean(errors.notificationEmail)}>
                {errors.notificationEmail?.message}
              </ValidationError>
            </Label>

            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={watchSendMerchantPaymentEmailNotification}
                onChange={checked => {
                  setValue('sendMerchantPaymentEmailNotification', checked);
                }}
                className={cn(
                  watchSendMerchantPaymentEmailNotification ? 'bg-primary-500' : 'bg-gray-200',
                  'focus:ring-primary-500 relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    watchSendMerchantPaymentEmailNotification ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-gray-900">{i18n._('Payment Confirmation')}</span>
              </Switch.Label>
            </Switch.Group>

            <Switch.Group as="div" className="flex items-center">
              <Switch
                className={cn(
                  'bg-primary-500',
                  'focus:ring-primary-500 relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    'translate-x-5',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-gray-900">{i18n._('Refunds')}</span>
              </Switch.Label>
            </Switch.Group>

            <Switch.Group as="div" className="flex items-center">
              <Switch
                className={cn(
                  'bg-primary-500',
                  'focus:ring-primary-500 relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    'translate-x-5',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-gray-900">{i18n._('Chargebacks')}</span>
              </Switch.Label>
            </Switch.Group>
          </div>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
