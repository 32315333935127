import React, {InputHTMLAttributes, ReactNode, forwardRef} from 'react';

type InputRadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label: ReactNode;
  wrapperClassName?: string;
  inputClassName?: string;
};

export const InputRadio = forwardRef<HTMLInputElement, InputRadioProps>(
  ({wrapperClassName, inputClassName, ...props}, ref) => {
    return (
      <div className={wrapperClassName ?? 'flex items-center'}>
        <input
          {...props}
          id={`field-${props.name}-${props.value}`}
          type="radio"
          ref={ref}
          className={
            inputClassName ??
            'w-4 h-4 bg-gray-100 border-gray-300 cursor-pointer text-primary-600 focus:ring-primary-500 disabled:opacity-70'
          }
        />
        <label
          htmlFor={`field-${props.name}-${props.value}`}
          className="text-sm font-medium text-gray-900 cursor-pointer ms-2"
        >
          {props.label}
        </label>
      </div>
    );
  }
);
