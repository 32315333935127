import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {ChargebackListItemOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {CurrencyCode, LocaleCode, formatAmount, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  SlideOverWithBrandedHeader,
  Typography,
  chargebackDisputeStatusMap,
  chargebackDisputeStatusToColor,
  displayChargebackTypeMap,
} from '../../../';
import {DisputeApiDetails} from './dispute-api-details';
import {SidebarPaymentDetails} from './sidebar-payment-details';

export const chargebackEventDisplayMap: {
  [key: string]: string;
} = {
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  NOTIFICATION_OF_FRAUD: 'Notification Of Fraud',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  REQUEST_FOR_INFORMATION: 'Request For Information',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  NOTIFICATION_OF_CHARGEBACK: 'Notification of Chargeback',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  CHARGEBACK: 'Chargeback',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  SECOND_CHARGEBACK: 'Second Chargeback',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  CHARGEBACK_REVERSED: 'Chargeback Reversed',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  PREARBITRATION_WON: 'Dispute Won',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  PREARBITRATION_LOST: 'Dispute Lost',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  DISPUTE_DEFENSE_PERIOD_ENDED: 'Dispute DEFENSE PERIOD ENDED',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  INFORMATION_SUPPLIED: 'Information Supplied',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  ISSUER_RESPONSE_TIMEFRAME_EXPIRED: 'Issuer Response Timeframe Expired',
  // biome-ignore lint/style/useNamingConvention: Adyen convention mapping
  ISSUER_COMMENTS: 'Issuer Comments',
};

type ChargebackDetailsPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  chargebackRow: ChargebackListItemOutput | null;
  showProfitAndProcessingFee?: boolean;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};

export const ChargebackDetailsPanel = ({
  isOpen,
  onCancel,
  chargebackRow,
  showProfitAndProcessingFee,
  trpc,
}: ChargebackDetailsPanelProps) => {
  const {i18n} = useLingui();

  if (!chargebackRow) {
    return null;
  }
  const {
    payment,
    type,
    pspReferenceId,
    amount,
    currency,
    disputeStatus,
    autoDefended,
    defendable,
    reasonCode,
    reason,
    defensePeriodEndsAt,
    createdAt,
    updatedAt,
    chargebackEvents,
  } = chargebackRow;

  const formattedAmount = formatAmount(amount, i18n.locale as LocaleCode, currency as CurrencyCode);

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('View Dispute', {type: displayChargebackTypeMap[type]})}
      closeHandler={onCancel}
    >
      <>
        <Typography variant="header-section" className="mb-8">
          {i18n._('Dispute Details')}
        </Typography>
        <div className="px-4 border-gray-100 sm:px-0">
          <dl className="divide-y divide-gray-100">
            <div className="pb-6 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Dispute ID')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {pspReferenceId}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Amount')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {formattedAmount}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Type')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {i18n._(displayChargebackTypeMap[type]) ?? i18n._(type)}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Dispute Status')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <FlatPillWithDot
                  color={chargebackDisputeStatusToColor[disputeStatus] || 'blue'}
                  label={chargebackDisputeStatusMap[disputeStatus] ?? disputeStatus}
                />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Auto Defended')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {autoDefended ? i18n._('Yes') : i18n._('No')}
              </dd>
            </div>
            {!autoDefended && (
              <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {i18n._('Defendable')}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {defendable ? i18n._('Yes') : i18n._('No')}
                </dd>
              </div>
            )}
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Reason Code')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {reasonCode}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Reason Message')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {reason}
              </dd>
            </div>
            {defensePeriodEndsAt && (
              <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {i18n._('Defense End Date')}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {formatLocaleDate(defensePeriodEndsAt)}
                </dd>
              </div>
            )}
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Last updated')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {formatLocaleDate(updatedAt)}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Created')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {formatLocaleDate(createdAt)}
              </dd>
            </div>
            <div />
          </dl>
        </div>
        <Typography variant="header-section" className="my-8">
          {i18n._('Payment Details')}
        </Typography>
        <SidebarPaymentDetails
          paymentRow={payment}
          merchantAccountName={chargebackRow.merchantAccount.businessName}
          showProfit={showProfitAndProcessingFee}
          withFinalHorizontalDivide
        />
        <Typography variant="header-section" className="my-8">
          {i18n._('Dispute History')}
        </Typography>
        {chargebackEvents.map((event, i) => (
          <div key={`chargeback-event-${i}`}>
            <Typography variant="header-subsection" className="my-4">
              {chargebackEvents.length - i}. {formatLocaleDate(event.createdAt)}
            </Typography>
            <div className="border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Type')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {chargebackEventDisplayMap[event.type] ?? event.type}
                  </dd>
                </div>
                <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    {i18n._('Dispute Status')}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <FlatPillWithDot
                      color={chargebackDisputeStatusToColor[event.disputeStatus] || 'blue'}
                      label={chargebackDisputeStatusMap[event.disputeStatus] ?? event.disputeStatus}
                    />
                  </dd>
                </div>
                <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    {i18n._('Reason Message')}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {event.reason}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        ))}
        <DisputeApiDetails trpc={trpc} disputePspReference={pspReferenceId} i18n={i18n} />
      </>
    </SlideOverWithBrandedHeader>
  );
};
