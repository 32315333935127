import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {
  InvitedOrganizationUserItem,
  InvitedOrganizationUsersOutput,
  InvitedTenantUserItem,
  InvitedTenantUsersOutput,
} from '@zentact/api/src/trpc/routers/usersRouter';
import {Dispatch, SetStateAction} from 'react';
import {TableSortValue, TableWrapper} from '../../../../table';
import {getInvitedUsersColumns} from './columns';

type Props = {
  usersList?: InvitedOrganizationUsersOutput | InvitedTenantUsersOutput;
  filters: {
    status: [];
  };
  noDataMessage?: JSX.Element;
  isLoading?: boolean;
  setFilters: Dispatch<SetStateAction<{status: []}>>;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string> | null>>;
  invitedUsersRefetch: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  mode: 'organizationUser' | 'tenantUser';
  openInvitedUserEditPanel: (row: InvitedOrganizationUserItem | InvitedTenantUserItem) => void;
};

export const InvitedUsersList = ({
  usersList,
  filters,
  setFilters,
  sort,
  setSort,
  noDataMessage,
  isLoading,
  invitedUsersRefetch,
  trpc,
  mode,
  openInvitedUserEditPanel,
}: Props) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      columns={getInvitedUsersColumns({
        invitedUsersRefetch,
        i18n,
        trpc,
        mode,
        openInvitedUserEditPanel,
      })}
      data={usersList?.users ?? []}
      filterValues={filters}
      onFilterChange={({id, value}) => {
        setFilters(prev => ({...prev, [id]: value}));
      }}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={noDataMessage}
      isLoading={isLoading}
      onRowClick={openInvitedUserEditPanel}
      excludeLastColumnFromRowClick
    />
  );
};
