import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {ComponentProps, Fragment, Ref, forwardRef, useMemo} from 'react';
import {Label} from '../..';
import {cn} from '../../utils';

export type InputSelectOption<T extends string> = {
  id: T;
  label: string;
  iconUrl?: string;
  disabled?: boolean;
  className?: string;
  activeClassName?: string;
  tooltipText?: string;
};
export type InputSelectProps<T extends string> = Omit<
  ComponentProps<typeof Listbox>,
  'value' | 'onChange'
> & {
  options: InputSelectOption<T>[];
  value?: T | T[];
  onChange?: (value: T | T[]) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  selectedItemLabel?: string;
};

function InputSelectComponent<Id extends string>(
  props: InputSelectProps<Id>,
  ref: Ref<HTMLDivElement>
) {
  const {
    options,
    value,
    onChange,
    placeholder,
    className,
    label,
    labelClassName,
    selectedItemLabel,
    ...restProps
  } = props;
  const selectedItem = useMemo<InputSelectOption<Id> | undefined>(
    () => options.find(opt => opt.id === value),
    [options, value]
  );

  const placeholderLabel = <span className="flex items-center text-gray-400">{placeholder}</span>;

  const selectedElement = props.multiple ? (
    (Array.isArray(value) &&
      options
        ?.filter(opt => value.includes(opt.id))
        ?.map(({label}) => label)
        .join(',')) ||
    placeholderLabel
  ) : selectedItem ? (
    <span className="flex items-center">
      {selectedItem.iconUrl ? (
        <img src={selectedItem.iconUrl} alt="" className="w-5 h-5 rounded-full shrink-0" />
      ) : null}
      <span className={cn('block truncate', selectedItem.iconUrl && 'ml-3')}>
        {selectedItem.label}
      </span>
    </span>
  ) : (
    placeholderLabel
  );

  return (
    <Listbox
      as="div"
      {...restProps}
      value={value}
      onChange={onChange}
      className={cn('relative', className)}
      ref={ref}
    >
      {({open}) => (
        <>
          <Label text={label} className={labelClassName}>
            <Listbox.Button className="focus:ring-primary-500 relative min-h-[2.25rem] w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 disabled:bg-slate-100 sm:text-sm sm:leading-6">
              {selectedItemLabel || selectedElement}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
          </Label>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {options.map(option => (
                <Listbox.Option
                  key={option.id}
                  className={({active}) =>
                    cn(
                      active
                        ? cn('bg-primary-600 text-white', option.activeClassName)
                        : cn('text-gray-900', option.className),
                      'relative cursor-default select-none py-2 pl-3 pr-9 '
                    )
                  }
                  data-te-toggle={!!option.tooltipText && 'tooltip'}
                  title={option.tooltipText}
                  value={option.id}
                  disabled={option.disabled}
                >
                  {({selected, active}) => (
                    <>
                      <div className="flex items-center">
                        {option.iconUrl ? (
                          <img
                            src={option.iconUrl}
                            className="w-5 h-5 rounded-full shrink-0"
                            alt="icon"
                          />
                        ) : null}
                        <span
                          className={cn(
                            selected ? 'font-semibold' : 'font-normal',
                            option.iconUrl && 'ml-3',
                            'block truncate'
                          )}
                        >
                          {option.label}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={cn(
                            active ? 'text-white' : 'text-primary-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
}

export const InputSelect = forwardRef(InputSelectComponent);

InputSelect.displayName = 'InputSelect';
