import {Prisma} from '@zentact/db';

import {IdPrefix} from './id';

type FormatProps = {
  type: string;
  status: string;
  category: string;
  direction: string | null;
  description: string | null;
  reference: string | null;
};

export const formatTransferCategory = (props: FormatProps) => {
  const isReversed = props.status === 'captureReversed';
  const isPlatformPayment = props.category === 'platformPayment';
  const isIncoming = props.direction === 'incoming';
  const isOutgoing = props.direction === 'outgoing';
  const description = props.description?.toLowerCase();

  const isOutgoingProcessingFee = isPlatformPayment && isOutgoing && props.type === 'capture';
  if (isOutgoingProcessingFee) {
    return isReversed
      ? DisplayCategoryMap.PROCESSING_FEE_REVERSED
      : DisplayCategoryMap.PROCESSING_FEE;
  }

  const isOutgoingChargeback = isPlatformPayment && isOutgoing && props.type === 'chargeback';
  if (isOutgoingChargeback) {
    if (description?.includes('chargeback fee')) {
      return DisplayCategoryMap.CHARGEBACK_FEE;
    }

    if (description?.includes('fixed fee') || description?.includes('variable fee')) {
      return DisplayCategoryMap.CHARGEBACK_COMMISSION_RETURN;
    }

    return DisplayCategoryMap.CHARGEBACK;
  }

  const isOutgoingRefund = isPlatformPayment && isOutgoing && props.type === 'refund';
  if (isOutgoingRefund) {
    if (description?.includes('refund fee')) {
      return DisplayCategoryMap.REFUND_FEE;
    }

    if (description?.includes('fixed fee') || description?.includes('variable fee')) {
      return DisplayCategoryMap.REFUND_PAYMENT_COMMISSION;
    }

    if (description?.includes('aggregated fee')) {
      return DisplayCategoryMap.REFUND_PROCESSING_FEE;
    }

    return DisplayCategoryMap.REFUND;
  }

  const isIncomingPlatformPayment = isPlatformPayment && isIncoming && props.type === 'capture';

  if (isIncomingPlatformPayment) {
    if (description?.includes('tip')) {
      return DisplayCategoryMap.TIP;
    }

    if (description?.includes('fixed fee') || description?.includes('variable fee')) {
      return isReversed
        ? DisplayCategoryMap.PAYMENT_COMMISSION_REVERSED
        : DisplayCategoryMap.PAYMENT_COMMISSION;
    }

    return isReversed ? DisplayCategoryMap.PAYMENT_REVERSED : DisplayCategoryMap.PAYMENT;
  }

  const isInternalTransfer = props.type === 'internalTransfer';
  if (isInternalTransfer) {
    if (description?.includes('monthly fee')) {
      return isIncoming ? DisplayCategoryMap.COLLECTED_MONTHLY_FEE : DisplayCategoryMap.MONTHLY_FEE;
    }

    if (description?.includes('annual fee')) {
      return isIncoming ? DisplayCategoryMap.COLLECTED_ANNUAL_FEE : DisplayCategoryMap.ANNUAL_FEE;
    }

    if (description?.includes('refund fee')) {
      return isIncoming ? DisplayCategoryMap.COLLECTED_REFUND_FEE : DisplayCategoryMap.REFUND_FEE;
    }

    if (description?.includes('chargeback')) {
      return isIncoming
        ? DisplayCategoryMap.COLLECTED_CHARGEBACK_FEE
        : DisplayCategoryMap.CHARGEBACK_FEE;
    }

    if (props.reference?.startsWith(IdPrefix.INTERNAL_FUNDS_TRANSFER)) {
      return DisplayCategoryMap.TRANSFER;
    }

    return DisplayCategoryMap.INTERNAL;
  }

  if (props.type === 'depositCorrection') {
    return DisplayCategoryMap.DEPOSIT_CORRECTION;
  }

  if (props.type === 'invoiceDeduction') {
    return DisplayCategoryMap.INVOICE_DEDUCTION;
  }

  if (props.type === 'leftover') {
    return DisplayCategoryMap.LEFTOVER;
  }

  if (props.type === 'fee') {
    return DisplayCategoryMap.PLATFORM_FEES;
  }

  return props.category;
};

export function getCategoryConditions(): {
  name: CategoryEnum;
  condition: Prisma.PlatformTransferEventWhereInput;
}[] {
  return [
    {
      name: CategoryEnum.PROCESSING_FEE,
      condition: {
        type: 'capture',
        category: 'platformPayment',
        direction: 'outgoing',
      },
    },
    {
      name: CategoryEnum.CHARGEBACK_FEE,
      condition: {
        type: 'chargeback',
        category: 'platformPayment',
        direction: 'outgoing',
        description: {contains: 'chargeback fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.CHARGEBACK_COMMISSION_RETURN,
      condition: {
        type: 'chargeback',
        category: 'platformPayment',
        direction: 'outgoing',
        // biome-ignore lint/style/useNamingConvention: <explanation>
        OR: [
          {description: {contains: 'fixed fee', mode: 'insensitive'}},
          {description: {contains: 'variable fee', mode: 'insensitive'}},
        ],
      },
    },
    {
      name: CategoryEnum.CHARGEBACK,
      condition: {
        type: 'chargeback',
        category: 'platformPayment',
        direction: 'outgoing',
        // biome-ignore lint/style/useNamingConvention: <explanation>
        OR: [
          {
            description: null,
          },
          {
            // biome-ignore lint/style/useNamingConvention: <explanation>
            AND: [
              {description: {not: {contains: 'chargeback fee'}, mode: 'insensitive'}},
              {description: {not: {contains: 'variable fee'}, mode: 'insensitive'}},
              {description: {not: {contains: 'fixed fee'}, mode: 'insensitive'}},
            ],
          },
        ],
      },
    },
    {
      name: CategoryEnum.REFUND_FEE,
      condition: {
        type: 'refund',
        category: 'platformPayment',
        direction: 'outgoing',
        description: {contains: 'refund fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.REFUND_PAYMENT_COMMISSION,
      condition: {
        type: 'refund',
        category: 'platformPayment',
        direction: 'outgoing',
        // biome-ignore lint/style/useNamingConvention: <explanation>
        OR: [
          {description: {contains: 'fixed fee', mode: 'insensitive'}},
          {description: {contains: 'variable fee', mode: 'insensitive'}},
        ],
      },
    },
    {
      name: CategoryEnum.REFUND_PROCESSING_FEE,
      condition: {
        type: 'refund',
        category: 'platformPayment',
        direction: 'outgoing',
        description: {contains: 'aggregated fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.REFUND,
      condition: {
        type: 'refund',
        category: 'platformPayment',
        direction: 'outgoing',
        // biome-ignore lint/style/useNamingConvention: <explanation>
        OR: [
          {
            description: null,
          },
          {
            // biome-ignore lint/style/useNamingConvention: <explanation>
            AND: [
              {
                description: {
                  not: {contains: 'refund fee'},
                  mode: 'insensitive',
                },
              },
              {
                description: {
                  not: {contains: 'fixed fee'},
                  mode: 'insensitive',
                },
              },
              {
                description: {
                  not: {contains: 'variable fee'},
                  mode: 'insensitive',
                },
              },
              {
                description: {
                  not: {contains: 'aggregated fee'},
                  mode: 'insensitive',
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: CategoryEnum.PAYMENT_COMMISSION,
      condition: {
        type: 'capture',
        category: 'platformPayment',
        direction: 'incoming',
        // biome-ignore lint/style/useNamingConvention: <explanation>
        OR: [
          {description: {contains: 'fixed fee', mode: 'insensitive'}},
          {description: {contains: 'variable fee', mode: 'insensitive'}},
        ],
      },
    },
    {
      name: CategoryEnum.PAYMENT,
      condition: {
        type: 'capture',
        category: 'platformPayment',
        direction: 'incoming',
        // biome-ignore lint/style/useNamingConvention: <explanation>
        OR: [
          {
            description: null,
          },
          {
            // biome-ignore lint/style/useNamingConvention: <explanation>
            AND: [
              {description: {not: {contains: 'fixed fee'}, mode: 'insensitive'}},
              {description: {not: {contains: 'variable fee'}, mode: 'insensitive'}},
              {description: {not: {contains: 'tip'}, mode: 'insensitive'}},
            ],
          },
        ],
      },
    },
    {
      name: CategoryEnum.TIP,
      condition: {
        type: 'capture',
        category: 'platformPayment',
        direction: 'incoming',
        description: {contains: 'tip', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.COLLECTED_MONTHLY_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'incoming',
        description: {contains: 'monthly fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.MONTHLY_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'outgoing',
        description: {contains: 'monthly fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.COLLECTED_ANNUAL_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'incoming',
        description: {contains: 'annual fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.ANNUAL_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'outgoing',
        description: {contains: 'annual fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.COLLECTED_REFUND_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'incoming',
        description: {contains: 'refund fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.REFUND_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'outgoing',
        description: {contains: 'refund fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.COLLECTED_CHARGEBACK_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'incoming',
        description: {contains: 'chargeback fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.CHARGEBACK_FEE,
      condition: {
        type: 'internalTransfer',
        direction: 'outgoing',
        description: {contains: 'chargeback fee', mode: 'insensitive'},
      },
    },
    {
      name: CategoryEnum.DEPOSIT_CORRECTION,
      condition: {
        type: 'depositCorrection',
      },
    },
    {
      name: CategoryEnum.INVOICE_DEDUCTION,
      condition: {
        type: 'invoiceDeduction',
      },
    },
    {
      name: CategoryEnum.LEFTOVER,
      condition: {
        type: 'leftover',
      },
    },
    {
      name: CategoryEnum.PLATFORM_FEES,
      condition: {
        type: 'fee',
      },
    },
    {
      name: CategoryEnum.INTERNAL,
      condition: {
        type: 'internalTransfer',
        category: 'internal',
        // biome-ignore lint/style/useNamingConvention: <explanation>
        AND: [
          {description: {not: {contains: 'monthly fee'}, mode: 'insensitive'}},
          {description: {not: {contains: 'annual fee'}, mode: 'insensitive'}},
          {description: {not: {contains: 'refund fee'}, mode: 'insensitive'}},
          {description: {not: {contains: 'chargeback fee'}, mode: 'insensitive'}},
          {reference: {not: {startsWith: IdPrefix.INTERNAL_FUNDS_TRANSFER}, mode: 'insensitive'}},
        ],
      },
    },
  ];
}

export enum CategoryEnum {
  PROCESSING_FEE = 'PROCESSING_FEE',
  PROCESSING_FEE_REVERSED = 'PROCESSING_FEE_REVERSED',
  CHARGEBACK_FEE = 'CHARGEBACK_FEE',
  CHARGEBACK_COMMISSION_RETURN = 'CHARGEBACK_COMMISSION_RETURN',
  CHARGEBACK = 'CHARGEBACK',
  REFUND_FEE = 'REFUND_FEE',
  REFUND_PAYMENT_COMMISSION = 'REFUND_PAYMENT_COMMISSION',
  REFUND_PROCESSING_FEE = 'REFUND_PROCESSING_FEE',
  REFUND = 'REFUND',
  PAYMENT_COMMISSION = 'PAYMENT_COMMISSION',
  PAYMENT_COMMISSION_REVERSED = 'PAYMENT_COMMISSION_REVERSED',
  PAYMENT = 'PAYMENT',
  PAYMENT_REVERSED = 'PAYMENT_REVERSED',
  COLLECTED_MONTHLY_FEE = 'COLLECTED_MONTHLY_FEE',
  MONTHLY_FEE = 'MONTHLY_FEE',
  COLLECTED_ANNUAL_FEE = 'COLLECTED_ANNUAL_FEE',
  ANNUAL_FEE = 'ANNUAL_FEE',
  COLLECTED_REFUND_FEE = 'COLLECTED_REFUND_FEE',
  COLLECTED_CHARGEBACK_FEE = 'COLLECTED_CHARGEBACK_FEE',
  DEPOSIT_CORRECTION = 'DEPOSIT_CORRECTION',
  INVOICE_DEDUCTION = 'INVOICE_DEDUCTION',
  LEFTOVER = 'LEFTOVER',
  PLATFORM_FEES = 'PLATFORM_FEES',
  TIP = 'TIP',
  TRANSFER = 'TRANSFER',
  INTERNAL = 'INTERNAL',
}

export const DisplayCategoryMap: Record<CategoryEnum, string> = {
  [CategoryEnum.PROCESSING_FEE]: 'Processing Fee',
  [CategoryEnum.PROCESSING_FEE_REVERSED]: 'Processing Fee Reversed',
  [CategoryEnum.CHARGEBACK_FEE]: 'Chargeback Fee',
  [CategoryEnum.CHARGEBACK_COMMISSION_RETURN]: 'Chargeback Commission Return',
  [CategoryEnum.CHARGEBACK]: 'Chargeback',
  [CategoryEnum.REFUND_FEE]: 'Refund Fee',
  [CategoryEnum.REFUND_PAYMENT_COMMISSION]: 'Refund Payment Commission',
  [CategoryEnum.REFUND_PROCESSING_FEE]: 'Refund Processing Fee',
  [CategoryEnum.REFUND]: 'Refund',
  [CategoryEnum.PAYMENT_COMMISSION]: 'Payment Commission',
  [CategoryEnum.PAYMENT_COMMISSION_REVERSED]: 'Payment Commission Reversed',
  [CategoryEnum.PAYMENT]: 'Payment',
  [CategoryEnum.PAYMENT_REVERSED]: 'Payment Reversed',
  [CategoryEnum.COLLECTED_MONTHLY_FEE]: 'Collected Monthly Fee',
  [CategoryEnum.MONTHLY_FEE]: 'Monthly Fee',
  [CategoryEnum.COLLECTED_ANNUAL_FEE]: 'Collected Annual Fee',
  [CategoryEnum.ANNUAL_FEE]: 'Annual Fee',
  [CategoryEnum.COLLECTED_REFUND_FEE]: 'Collected Refund Fee',
  [CategoryEnum.COLLECTED_CHARGEBACK_FEE]: 'Collected Chargeback Fee',
  [CategoryEnum.DEPOSIT_CORRECTION]: 'Deposit Correction',
  [CategoryEnum.INVOICE_DEDUCTION]: 'Invoice Deduction',
  [CategoryEnum.LEFTOVER]: 'Leftover',
  [CategoryEnum.PLATFORM_FEES]: 'Platform Fees',
  [CategoryEnum.TIP]: 'Tip',
  [CategoryEnum.TRANSFER]: 'Transfer',
  [CategoryEnum.INTERNAL]: 'Internal',
};
