import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  DEFAULT_CURRENCY,
  fromMinorUnits,
  maxCreditCardFlatFee,
  maxInteracFlatFee,
  maxSurchargePercentageFee,
  toMinorUnits,
} from '@zentact/common';
import {
  Button,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

export const getUpdateMerchantSurchargeConfigurationSchema = () =>
  z.object({
    creditCardPercentageFee: z
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: t`Only numbers and decimals are allowed`,
      })
      .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
      .min(0)
      .max(
        maxSurchargePercentageFee,
        t`Percentage fee can not be higher than ${maxSurchargePercentageFee}`
      ),
    creditCardFlatFee: z
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: t`Only numbers and decimals are allowed`,
      })
      .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
      .min(0)
      .max(
        maxCreditCardFlatFee,
        t`Credit card flat fee can not be higher than ${maxCreditCardFlatFee}`
      ),
    interacFlatFee: z
      .number({
        // biome-ignore lint/style/useNamingConvention: Zod uses snake case
        invalid_type_error: t`Only numbers and decimals are allowed`,
      })
      .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
      .min(0)
      .max(maxInteracFlatFee, t`Max interac flat fee can not be higher than ${maxInteracFlatFee}`),
  });

export type UpdateMerchantSurchargeConfigurationData = z.infer<
  ReturnType<typeof getUpdateMerchantSurchargeConfigurationSchema>
>;

export type UpdateMerchantSurchargeConfigurationProps = {
  merchantAccountRow: MerchantAccountDetailsOutput | MerchantAccountsListItem;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateMerchantSurchargeConfiguration = ({
  merchantAccountRow,
  onClose,
  isOpen,
  triggerRefetch: refetchMerchantAccountsList,
}: UpdateMerchantSurchargeConfigurationProps) => {
  const {i18n} = useLingui();
  const surchargeConfiguration = merchantAccountRow.surchargeConfiguration;
  const merchantAccountId =
    'merchantAccount' in merchantAccountRow
      ? merchantAccountRow.merchantAccount?.id
      : merchantAccountRow.id;

  const form = useForm<UpdateMerchantSurchargeConfigurationData>({
    resolver: zodResolver(getUpdateMerchantSurchargeConfigurationSchema()),
    defaultValues: {
      creditCardPercentageFee: surchargeConfiguration?.creditCardPercentageFee ?? 0,
      creditCardFlatFee: fromMinorUnits(
        surchargeConfiguration?.creditCardFlatFee ?? 0,
        DEFAULT_CURRENCY
      ),
      interacFlatFee: fromMinorUnits(surchargeConfiguration?.interacFlatFee ?? 0, DEFAULT_CURRENCY),
    },
  });
  const {
    handleSubmit,
    formState: {errors},
    register,
  } = form;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const updateMerchantMutation =
    trpc.merchantAccount.updateMerchantAccountSurchargeConfiguration.useMutation({
      onSuccess: () => {
        refetchMerchantAccountsList();
        onClose();
        showSuccessNotification(t`Merchant Surcharge configuration updated!`);
      },
      onError: error => {
        showErrorNotification(t`Error`, error.message);
      },
    });

  const onUpdateMerchantSubmit = useCallback(
    (data: UpdateMerchantSurchargeConfigurationData) => {
      if (!merchantAccountId) {
        return;
      }
      updateMerchantMutation.mutate({
        merchantAccountId: merchantAccountId,
        surchargeConfiguration: {
          creditCardPercentageFee: data.creditCardPercentageFee,
          creditCardFlatFee: toMinorUnits(data.creditCardFlatFee, DEFAULT_CURRENCY),
          interacFlatFee: toMinorUnits(data.interacFlatFee, DEFAULT_CURRENCY),
        },
      });
      return;
    },
    [merchantAccountId]
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Update Merchant Surcharge Configuration')}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSubmit)}
              isLoading={updateMerchantMutation.isLoading}
            >
              <Trans>Update</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSubmit)}>
        <div>
          <div className="flex flex-col gap-3">
            <Label text={t`Credit Card Percentage Fee (${0}% - ${maxSurchargePercentageFee}%)`}>
              <InputText
                className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                type={'number'}
                {...register('creditCardPercentageFee', {
                  valueAsNumber: true,
                })}
                addonBefore="%"
                hasError={Boolean(errors.creditCardPercentageFee)}
              />
              <ValidationError isVisible={Boolean(errors.creditCardPercentageFee)}>
                {errors.creditCardPercentageFee?.message}
              </ValidationError>
            </Label>
            <Label text={t`Credit Card Flat Fee`}>
              <InputText
                className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                type={'number'}
                {...register('creditCardFlatFee', {
                  valueAsNumber: true,
                })}
                addonBefore="$"
                hasError={Boolean(errors.creditCardFlatFee)}
              />
              <ValidationError isVisible={Boolean(errors.creditCardFlatFee)}>
                {errors.creditCardFlatFee?.message}
              </ValidationError>
            </Label>
            <Label text={t`Interac Flat Fee`}>
              <InputText
                className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                type={'number'}
                {...register('interacFlatFee', {
                  valueAsNumber: true,
                })}
                addonBefore="$"
                hasError={Boolean(errors.interacFlatFee)}
              />
              <ValidationError isVisible={Boolean(errors.interacFlatFee)}>
                {errors.interacFlatFee?.message}
              </ValidationError>
            </Label>
          </div>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
