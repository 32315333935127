import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  Breadcrumbs,
  MerchantAccountsPicker,
  StatementList,
  TableSortValue,
  Typography,
} from '@zentact/ui-tailwind';
import {useEffect, useMemo, useState} from 'react';

const getBreadCrumbs = () => [
  {name: t`Finance`, href: RoutePath.FINANCE, current: false},
  {name: t`Statements`, href: RoutePath.STATEMENTS, current: true},
];

export const Statements = () => {
  const {activeAndInactiveMerchantAccounts: merchantAccounts} = useStore();
  const [selectedMerchantAccount, setSelectedMerchantAccount] = useState<string | undefined>(
    undefined
  );

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'date', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 24});

  const paymentFilters = useMemo(() => {
    return {
      where: {
        ...(selectedMerchantAccount && {merchantAccountId: selectedMerchantAccount}),
      },
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    };
  }, [selectedMerchantAccount, sort]);

  const statementList = trpc.statement.statementList.useQuery(
    {
      ...paymentFilters,
      ...pagination,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [paymentFilters]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Statements</Trans>
        </Typography>
        {!!merchantAccounts?.length && (
          <div className="mr-1 font-normal shrink-0 w-60">
            <MerchantAccountsPicker
              selectedMerchantAccount={selectedMerchantAccount}
              onSelectMerchantAccount={setSelectedMerchantAccount}
              merchantAccountsOptions={merchantAccounts}
              allLabel={t`All Merchant Accounts`}
            />
          </div>
        )}
      </div>
      <div className="mt-4 overflow-x-auto">
        <div className="mb-4 text-sm leading-6 text-gray-500">
          <Trans>
            You can view up to 7 years of statements. New statements will usually post on the first
            of the next month.
          </Trans>
        </div>
        <StatementList
          statementList={statementList.data}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          trpc={trpc}
          isLoading={
            statementList.isLoading || (statementList.isRefetching && statementList.isPreviousData)
          }
        />
      </div>
    </div>
  );
};
