import {ReactNode} from 'react';
import {cn} from '../../utils';

export type LabelProps = {children: ReactNode; text?: ReactNode | string; className?: string};

export const Label = ({children, text, className}: LabelProps) => {
  return (
    <label className={cn(`flex flex-col gap-2 ${className}`)}>
      {text && <div className="text-sm font-medium leading-6 text-gray-900">{text}</div>}
      {children}
    </label>
  );
};
