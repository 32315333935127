import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {PayoutsSummaryItem} from '@zentact/api/src/trpc/routers/financeRouter';
import {formatLocaleDate} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  DashboardDatepicker,
  MerchantAccountsPicker,
  PayoutDetailsPanel,
  PayoutList,
  TableSortValue,
  Typography,
  useNotification,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useCallback, useEffect, useState} from 'react';
import {z} from 'zod';
import {exportToCsvPayouts} from './csv-export';

const getBreadCrumbs = () => [
  {name: t`Finance`, href: RoutePath.FINANCE, current: false},
  {name: t`Payouts`, href: RoutePath.PAYOUTS, current: true},
];

export const Payouts = () => {
  const {activeAndInactiveMerchantAccounts: merchantAccounts} = useStore();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [sort, setSort] = useState<TableSortValue<string>>({
    columnId: 'valueDate',
    value: 'desc',
  });
  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    z.object({
      selectedMerchantAccount: z.string().optional(),
      startDate: z
        .date()
        .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
        .nullable()
        .optional(),
      endDate: z
        .date()
        .or(
          z
            .string()
            .transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
        )
        .nullable()
        .optional(),
    })
  );

  const selectedMerchantAccount = typedSearchParams?.selectedMerchantAccount;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue =
    startDate && endDate
      ? {startDate, endDate}
      : {
          startDate: DateTime.now().startOf('year').toJSDate(),
          endDate: DateTime.now().endOf('year').toJSDate(),
        };

  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const payoutsDayListSummary = trpc.finance.getPayoutsSummary.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {columnId: sort.columnId, value: sort.value}}),
      where: {
        ...(dateValue && {
          startDate: dateValue.startDate,
          endDate: dateValue.endDate,
        }),
        ...(selectedMerchantAccount && {merchantAccountId: selectedMerchantAccount}),
      },
    },
    {keepPreviousData: true}
  );

  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [payoutDetailsRow, setPayoutDetailsRow] = useState<PayoutsSummaryItem | null>(null);

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams({startDate: dateRange.startDate, endDate: dateRange.endDate});
    },
    [setTypedSearchParams]
  );

  const handleOpenSidePanel = useCallback(
    (row: PayoutsSummaryItem) => {
      setPayoutDetailsRow(row);
      openSidePanel();
    },
    [openSidePanel, setPayoutDetailsRow]
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [selectedMerchantAccount, sort]);

  const trpcContext = trpc.useUtils();
  const {i18n} = useLingui();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullPayoutsList = await trpcContext.finance.getPayoutsSummary.fetch({
        ...(sort?.columnId &&
          sort.value && {orderBy: {columnId: sort.columnId, value: sort.value}}),
        where: {
          ...(dateValue && {
            startDate: dateValue.startDate,
            endDate: dateValue.endDate,
          }),
          ...(selectedMerchantAccount && {merchantAccountId: selectedMerchantAccount}),
        },
      });
      exportToCsvPayouts(fullPayoutsList, i18n);
      showSuccessNotification(t`Payouts .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payouts .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, selectedMerchantAccount, sort, exportToCsvPayouts]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex flex-wrap items-center justify-between pt-4">
        <Typography variant="header-page" className="flex">
          <Trans>Payouts For</Trans> {formatLocaleDate(dateValue.startDate, 'short')}
          {' - '}
          {formatLocaleDate(dateValue.endDate, 'short')}
        </Typography>
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          {!!merchantAccounts?.length && (
            <div className="font-normal shrink-0 w-60">
              <MerchantAccountsPicker
                selectedMerchantAccount={selectedMerchantAccount}
                onSelectMerchantAccount={selectedMerchantAccount =>
                  setTypedSearchParams({selectedMerchantAccount})
                }
                merchantAccountsOptions={merchantAccounts}
                allLabel={t`All Merchant Accounts`}
              />
            </div>
          )}
          <div className="w-full mt-4 shrink-0 sm:mt-0 sm:w-64">
            <DashboardDatepicker
              onChange={handleDateChange}
              dateRange={{
                startDate: DateTime.fromJSDate(dateValue.startDate).startOf('day').toJSDate(),
                endDate: dateValue.endDate
                  ? DateTime.fromJSDate(dateValue.endDate).endOf('day').toJSDate()
                  : DateTime.fromJSDate(dateValue.startDate).endOf('day').toJSDate(),
              }}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!payoutsDayListSummary.data || payoutsDayListSummary.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto">
        <PayoutList
          payoutsList={payoutsDayListSummary.data}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleOpenSidePanel}
          activityDetailsPath={RoutePath.FINANCE_ACCOUNT_ACTIVITY}
          showMerchantColumn
          isLoading={
            payoutsDayListSummary.isLoading ||
            (payoutsDayListSummary.isRefetching && payoutsDayListSummary.isPreviousData)
          }
        />
      </div>
      <PayoutDetailsPanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        payoutRow={payoutDetailsRow}
        activityDetailsPath={RoutePath.FINANCE_ACCOUNT_ACTIVITY}
      />
      <p className="mt-1 text-xs leading-5 text-gray-500">
        <Trans>
          *Deposit time varies by bank and can take 24-48 hours to appear in your account.
        </Trans>
      </p>
    </div>
  );
};
