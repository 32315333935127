export enum ErrorCode {
  ERROR_GENERIC = 'ERROR_GENERIC',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  ACCESS_DENIED = 'ACCESS_DENIED',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  SCHEMA_VALIDATION_ERROR = 'SCHEMA_VALIDATION_ERROR',
  BALANCE_ACCOUNT_IS_NOT_ACTIVE = 'BALANCE_ACCOUNT_IS_NOT_ACTIVE',
  MERCHANT_REGISTRATION_NOT_FOUND_OR_SESSION_EXPIRED = 'MERCHANT_REGISTRATION_NOT_FOUND_OR_SESSION_EXPIRED',
  MERCHANT_REGISTRATION_NOT_FOUND_FOR_USER = 'MERCHANT_REGISTRATION_NOT_FOUND_FOR_USER',
  MERCHANT_REGISTRATION_REVOKED = 'MERCHANT_REGISTRATION_REVOKED',
  MERCHANT_REGISTRATION_ALREADY_EXISTS = 'MERCHANT_REGISTRATION_ALREADY_EXISTS',
  MERCHANT_REGISTRATION_WITH_EMAIL_ALREADY_EXISTS = 'MERCHANT_REGISTRATION_WITH_EMAIL_ALREADY_EXISTS',
  MERCHANT_REGISTRATION_INVALID_STATUS = 'MERCHANT_REGISTRATION_INVALID_STATUS',
  MERCHANT_REFERENCE_ID_ALREADY_EXISTS = 'MERCHANT_REFERENCE_ID_ALREADY_EXISTS',
  MERCHANT_REFERENCE_ID_REQUIRED = 'MERCHANT_REFERENCE_ID_REQUIRED',
  MERCHANT_ACCOUNT_ALREADY_REGISTERED = 'MERCHANT_ACCOUNT_ALREADY_REGISTERED',
  MERCHANT_ACCOUNT_NOT_FOUND = 'MERCHANT_ACCOUNT_NOT_FOUND',
  MERCHANT_ACCOUNT_IS_NOT_ACTIVE = 'MERCHANT_ACCOUNT_IS_NOT_ACTIVE',
  MERCHANT_ACCOUNT_IS_PENDING = 'MERCHANT_ACCOUNT_IS_PENDING',
  MERCHANT_ACCOUNT_IS_INVALID = 'MERCHANT_ACCOUNT_IS_INVALID',
  MERCHANT_IS_NOT_VERIFIED_IN_ADYEN = 'MERCHANT_IS_NOT_VERIFIED_IN_ADYEN',
  MISSING_PARAMETERS = 'MISSING_PARAMETERS',
  CHECKOUT_ALREADY_COMPLETED = 'CHECKOUT_ALREADY_COMPLETED',
  CHECKOUT_SESSION_EXPIRED = 'CHECKOUT_SESSION_EXPIRED',
  CHECKOUT_REFERENCE_ID_ALREADY_EXISTS = 'CHECKOUT_REFERENCE_ID_ALREADY_EXISTS',
  CHECKOUT_NOT_FOUND = 'CHECKOUT_NOT_FOUND',
  CHECKOUT_ALREADY_PAID = 'CHECKOUT_ALREADY_PAID',
  PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
  STATEMENT_NOT_FOUND = 'STATEMENT_NOT_FOUND',
  NOT_SUPPORTED_LEGAL_ENTITY_TYPE = 'NOT_SUPPORTED_LEGAL_ENTITY_TYPE',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  ORGANIZATION_HAS_ACTIVE_MERHCNATS = 'ORGANIZATION_HAS_ACTIVE_MERHCNATS',
  ORGANIZATION_ALREADY_EXISTS = 'ORGANIZATION_ALREADY_EXISTS',
  ORGANIZATION_EMAIL_ALREADY_EXISTS = 'ORGANIZATION_EMAIL_ALREADY_EXISTS',
  NO_ACTIVE_ACCOUNTS_OR_REGISTRATIONS = 'NO_ACTIVE_ACCOUNTS_OR_REGISTRATIONS',
  INVITATION_IS_EXPIRED_OR_NOT_FOUND = 'INVITATION_IS_EXPIRED_OR_NOT_FOUND',
  PAYMENT_NOT_AVAILABLE_TO_REFUND_OR_VOID = 'PAYMENT_NOT_AVAILABLE_TO_REFUND_OR_VOID',
  PARTIAL_REFUND_NOT_AVAILABLE = 'PARTIAL_REFUND_NOT_AVAILABLE',
  USER_ALREADY_EXIST = 'USER_ALREADY_EXIST',
  LEGAL_BUSINESS_NAME_OR_ORGANIZATION_ID_IS_REQUIRED = 'LEGAL_BUSINESS_NAME_OR_ORGANIZATION_ID_IS_REQUIRED',
  STATE_IS_INVALID = 'STATE_IS_INVALID',
  POSTAL_CODE_IS_INVALID = 'POSTAL_CODE_IS_INVALID',
  PHONE_NUMBER_IS_INVALID = 'PHONE_NUMBER_IS_INVALID',
  CURRENCY_NOT_SUPPORTED = 'CURRENCY_NOT_SUPPORTED',
  USER_BELONGS_TO_ANOTHER_ORGANIZATION = 'USER_BELONGS_TO_ANOTHER_ORGANIZATION',
  WEBHOOK_HMAC_KEY_NOT_FOUND = 'WEBHOOK_HMAC_KEY_NOT_FOUND',
  WEBHOOK_CONFIG_NOT_FOUND = 'WEBHOOK_CONFIG_NOT_FOUND',
  WEBHOOK_CONFIG_ALREADY_EXISTS = 'WEBHOOK_CONFIG_ALREADY_EXISTS',
  MERCHANT_REGISTRATION_IS_NOT_AVAILABLE_FROM_PORTAL = 'MERCHANT_REGISTRATION_IS_NOT_AVAILABLE_FROM_PORTAL',
  SPLIT_CONFIGURATION_GROUP_ALREADY_EXISTS = 'SPLIT_CONFIGURATION_GROUP_ALREADY_EXISTS',
  SPLIT_CONFIGURATION_GROUP_DOES_NOT_EXIST = 'SPLIT_CONFIGURATION_GROUP_DOES_NOT_EXIST',
  SPLIT_CONFIGURATION_GROUP_CANNOT_BE_SET_TO_NOT_DEFAULT = 'SPLIT_CONFIGURATION_GROUP_CANNOT_BE_SET_TO_NOT_DEFAULT',
  SPLIT_CONFIGURATION_GROUP_IS_DEFAULT = 'SPLIT_CONFIGURATION_GROUP_IS_DEFAULT',
  SPLIT_CONFIGURATION_GROUP_IS_INACTIVE = 'SPLIT_CONFIGURATION_GROUP_IS_INACTIVE',
  NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
  SHOPPER_OR_STORED_PAYEMENT_METHOD_NOT_FOUND = 'SHOPPER_OR_STORED_PAYEMENT_METHOD_NOT_FOUND',
  STORED_PAYEMENT_METHOD_IS_NOT_ACTIVE = 'STORED_PAYEMENT_METHOD_IS_NOT_ACTIVE',
  STORED_PAYEMENT_METHOD_IS_EXPIRED = 'STORED_PAYEMENT_METHOD_IS_EXPIRED',
  SHOPPER_NOT_FOUND = 'SHOPPER_NOT_FOUND',
  SHOPPER_ALREADY_EXISTS = 'SHOPPER_ALREADY_EXISTS',
  INVALID_PAYMENT_LIST_REQUEST = 'INVALID_PAYMENT_LIST_REQUEST',
  FILE_TOO_BIG = 'FILE_TOO_BIG',
  UNSUPPORTED_FILE_EXTENSION = 'UNSUPPORTED_FILE_EXTENSION',
  WEB_ADDRESS_IS_INVALID = 'WEB_ADDRESS_IS_INVALID',
  PSP_MERCHANT_ACCOUNT_NAME_NOT_FOUND = 'PSP_MERCHANT_ACCOUNT_NAME_NOT_FOUND',
}
