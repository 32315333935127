export const paymentMethodVariantTranslationMap = new Map<string, string>(
  Object.entries({
    ach: 'ACH',
    amex: 'American Express',
    // biome-ignore lint/style/useNamingConvention:
    amex_amazonpay: 'Amazon Pay – American Express',
    // biome-ignore lint/style/useNamingConvention:
    amex_googlepay: 'Google Pay - American Express',
    // biome-ignore lint/style/useNamingConvention:
    amex_applepay: 'Apple Pay - American Express',
    // biome-ignore lint/style/useNamingConvention:
    amex_samsungpay: 'Samsung Pay - American Express',
    // biome-ignore lint/style/useNamingConvention:
    amex_vipps: 'Vipps - American Express',
    // biome-ignore lint/style/useNamingConvention:
    amex_mobilepay: 'MobilePay - American Express',
    amexcommercial: 'American Express Commercial Credit Card',
    amexconsumer: 'American Express Consumer Credit Card',
    amexcorporate: 'American Express Corporate Credit Card',
    amexdebit: 'American Express Debit Card',
    amexprepaid: 'American Express Prepaid Card',
    amexprepaidreloadable: 'American Express Prepaid-Reloadable Card',
    amexsmallbusiness: 'American Express Small Business Credit Card',
    discover: 'Discover',
    // biome-ignore lint/style/useNamingConvention:
    discover_amazonpay: 'Amazon Pay - Discover',
    // biome-ignore lint/style/useNamingConvention:
    discover_googlepay: 'Google Pay - Discover',
    // biome-ignore lint/style/useNamingConvention:
    discover_applepay: 'Apple Pay - Discover',
    // biome-ignore lint/style/useNamingConvention:
    discover_samsungpay: 'Samsung Pay - Discover',
    mc: 'Mastercard',
    // biome-ignore lint/style/useNamingConvention:
    mc_amazonpay: 'Amazon Pay - Mastercard',
    // biome-ignore lint/style/useNamingConvention:
    mc_googlepay: 'Google Pay - Mastercard',
    // biome-ignore lint/style/useNamingConvention:
    mc_applepay: 'Apple Pay - Mastercard',
    // biome-ignore lint/style/useNamingConvention:
    mc_samsungpay: 'Samsung Pay - Mastercard',
    // biome-ignore lint/style/useNamingConvention:
    mc_vipps: 'Vipps - Mastercard',
    // biome-ignore lint/style/useNamingConvention:
    mc_mobilepay: 'MobilePay - Mastercard',
    mcalphabankbonus: 'Mastercard Alpha Bank Bonus',
    mcatm: 'Mastercard ATM',
    mccommercialcredit: 'Mastercard Commercial Credit',
    mccommercialdebit: 'Mastercard Commercial Debit',
    mccommercialpremiumcredit: 'Mastercard Commercial Premium Credit',
    mccommercialpremiumdebit: 'Mastercard Commercial Premium Debit',
    mccorporate: 'Mastercard Corporate',
    mccorporatecredit: 'Mastercard Corporate Credit',
    mccorporatedebit: 'Mastercard Corporate Debit',
    mccredit: 'Mastercard Credit',
    mcdebit: 'Mastercard Debit',
    mcfleetcredit: 'Mastercard Fleet Credit',
    mcfleetdebit: 'Mastercard Fleet Debit',
    mcpremiumcredit: 'Mastercard Premium Credit',
    mcpremiumdebit: 'Mastercard Premium Debit',
    mcprepaidanonymous: 'Mastercard Prepaid Anonymous',
    mcpro: 'Mastercard Pro',
    mcpurchasingcredit: 'Mastercard Purchasing Credit',
    mcpurchasingdebit: 'Mastercard Purchasing Debit',
    mcstandardcredit: 'Mastercard Standard Credit',
    mcstandarddebit: 'Mastercard Standard Debit',
    mcsuperpremiumcredit: 'Mastercard Super Premium Credit',
    mcsuperpremiumdebit: 'Mastercard Super Premium Debit',
    visa: 'Visa',
    // biome-ignore lint/style/useNamingConvention:
    visa_amazonpay: 'Amazon Pay - Visa',
    // biome-ignore lint/style/useNamingConvention:
    visa_googlepay: 'Google Pay - Visa',
    // biome-ignore lint/style/useNamingConvention:
    visa_applepay: 'Apple Pay - Visa',
    // biome-ignore lint/style/useNamingConvention:
    visa_samsungpay: 'Samsung Pay - Visa',
    // biome-ignore lint/style/useNamingConvention:
    visa_vipps: 'Vipps - Visa',
    // biome-ignore lint/style/useNamingConvention:
    visa_mobilepay: 'MobilePay - Visa',
    visaalphabankbonus: 'Visa Alpha Bank Bonus',
    visabusiness: 'Visa Business',
    visacheckout: 'Visa Checkout',
    visaclassic: 'Visa Classic',
    visacommercialcredit: 'Visa Commercial Credit',
    visacommercialdebit: 'Visa Commercial Debit',
    visacommercialpremiumcredit: 'Visa Commercial Premium Credit',
    visacommercialpremiumdebit: 'Visa Commercial Premium Debit',
    visacommercialsuperpremiumcredit: 'Visa Commercial Super Premium Credit',
    visacommercialsuperpremiumdebit: 'Visa Commercial Super Premium Debit',
    visacorporate: 'Visa Corporate',
    visacorporatecredit: 'Visa Corporate Credit',
    visacorporatedebit: 'Visa Corporate Debit',
    visacredit: 'Visa credit card',
    visadankort: 'Visa Dankort',
    // biome-ignore lint/style/useNamingConvention:
    visadankort_mobilepay: 'MobilePay - Visa Dankort',
    visadebit: 'Visa debit card',
    visafleetcredit: 'Visa Fleet Credit',
    visafleetdebit: 'Visa Fleet Debit',
    visagold: 'Visa Gold',
    visahipotecario: 'Visa Hipotecario',
    visaplatinum: 'Visa Platinum',
    visapremiumcredit: 'Visa Premium Credit',
    visapremiumdebit: 'Visa Premium Debit',
    visaprepaidanonymous: 'Visa Prepaid Anonymous',
    visaproprietary: 'Visa Proprietary',
    visapurchasing: 'Visa Purchasing',
    visapurchasingcredit: 'Visa Purchasing Credit',
    visapurchasingdebit: 'Visa Purchasing Debit',
    visasaraivacard: 'Visa Saraiva Card',
    visasignature: 'Visa Signature',
    visastandardcredit: 'Visa Standard Credit',
    visastandarddebit: 'Visa Standard Debit',
    visasuperpremiumcredit: 'Visa Super Premium Credit',
    visasuperpremiumdebit: 'Visa Super Premium Debit',
    vpay: 'V Pay',
    electron: 'Visa Electron',
    // biome-ignore lint/style/useNamingConvention:
    electron_amazonpay: 'Amazon Pay - Visa Electron',
    // biome-ignore lint/style/useNamingConvention:
    electron_applepay: 'Apple Pay - Visa Electron',
    // biome-ignore lint/style/useNamingConvention:
    electron_samsungpay: 'Samsung Pay - Visa Electron',
    // biome-ignore lint/style/useNamingConvention:
    electron_vipps: 'Vipps - Visa Electron',
    // biome-ignore lint/style/useNamingConvention:
    electron_mobilepay: 'MobilePay - Visa Electron',
    interac: 'Interac(online banking)',
    // biome-ignore lint/style/useNamingConvention:
    interac_applepay: 'Apple Pay - Interac (in-app)',
    // biome-ignore lint/style/useNamingConvention:
    interac_card: 'Interac Debit for POS',
    // biome-ignore lint/style/useNamingConvention:
    interac_googlepay: 'Google Pay - Interac (in-app)',
    paypal: 'PayPal',
    // biome-ignore lint/style/useNamingConvention:
    paypal_pos: 'PayPal for POS',
    paysafecard: 'PaySafeCard',
  })
);

export const paymentMethodVariants = Array.from(paymentMethodVariantTranslationMap.keys());
