import {getPrimaryPaymentMethodPart} from '@zentact/common';
import {ReactElement, memo} from 'react';

export const AmexIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height={28} viewBox="0 0 40 26">
    <title>Amex</title>
    <path fill="#016FD0" d="M0 26h40V0H0v26z" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M30.69 13.63v1.64h-4.17v1.14h4.07v1.64h-4.07v1.12h4.17v1.66l3.38-3.6-3.38-3.6zm-1.1-6.14l-1.4-3.19h-4l-4.1 9.32h3.33v8.27l10.28.01 1.61-1.8 1.63 1.8H40v-2.63l-1.92-2.06L40 15.16v-2.59l-1.93.01V7.6l-1.81 4.98H34.5l-1.86-5v5h-4.2l-.6-1.46h-3.3l-.6 1.46h-2.22l3.23-7.27V5.3h2.55l3.19 7.21V5.3l3.1.01 1.6 4.47 1.62-4.48H40v-1h-3.77l-.85 2.39-.85-2.39h-4.94v3.19zm-5.06 6.11v7.27h6.16v-.01h2.54l2.1-2.32 2.12 2.32H40v-.1l-3.34-3.53L40 13.65v-.05h-2.52l-2.1 2.3-2.08-2.3h-8.77zm.7-4.11l.96-2.31.97 2.31h-1.93z"
    />
  </svg>
);

export const VisaIcon = () => {
  return (
    <div className="border border-gray-200">
      <svg xmlns="http://www.w3.org/2000/svg" height={28} viewBox="0 0 780 500">
        <title>Visa</title>
        <g strokeWidth="1" strokeLinecap="round">
          <path
            fill="#0E4595"
            d="M293.2 348.73l33.359-195.76h53.358l-33.384 195.76H293.2zm246.11-191.54c-10.569-3.966-27.135-8.222-47.821-8.222-52.726 0-89.863 26.551-90.181 64.604-.297 28.129 26.515 43.822 46.754 53.185 20.771 9.598 27.752 15.716 27.652 24.283-.133 13.123-16.586 19.115-31.924 19.115-21.355 0-32.701-2.967-50.225-10.273l-6.878-3.111-7.487 43.822c12.463 5.467 35.508 10.199 59.438 10.445 56.09 0 92.502-26.248 92.916-66.885.199-22.27-14.016-39.215-44.801-53.188-18.65-9.056-30.072-15.099-29.951-24.269 0-8.137 9.668-16.838 30.56-16.838 17.446-.271 30.088 3.534 39.936 7.5l4.781 2.259 7.231-42.427m137.31-4.223h-41.23c-12.772 0-22.332 3.486-27.94 16.234l-79.245 179.4h56.031s9.159-24.121 11.231-29.418c6.123 0 60.555.084 68.336.084 1.596 6.854 6.492 29.334 6.492 29.334h49.512l-43.187-195.64zm-65.417 126.41c4.414-11.279 21.26-54.724 21.26-54.724-.314.521 4.381-11.334 7.074-18.684l3.606 16.878s10.217 46.729 12.353 56.527h-44.293v.003zm-363.3-126.41l-52.239 133.5-5.565-27.129c-9.726-31.274-40.025-65.157-73.898-82.12l47.767 171.2 56.455-.063 84.004-195.39-56.524-.001"
          />
          <path
            fill="#F2AE14"
            d="M146.92 152.96H60.879l-.682 4.073c66.939 16.204 111.23 55.363 129.62 102.42l-18.709-89.96c-3.229-12.396-12.597-16.096-24.186-16.528"
          />
        </g>
      </svg>
    </div>
  );
};

export const MasterCardIcon = () => (
  <div className="border border-gray-200">
    <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 0 40 26">
      <title>MasterCard</title>
      <path fill="#fff" d="M0 0h40v26H0z" />
      <path fill="#F06022" d="M16.13 19.29h7.74V6.7h-7.74v12.58z" />
      <path
        fill="#EA1D25"
        d="M16.93 13A7.93 7.93 0 0120 6.71a8.02 8.02 0 00-10.65.65 7.96 7.96 0 000 11.28 8.02 8.02 0 0010.65.65A8.02 8.02 0 0116.93 13"
      />
      <path
        fill="#F79D1D"
        d="M33 13c0 2.12-.84 4.15-2.34 5.65a8.1 8.1 0 01-10.66.64A8.05 8.05 0 0023.07 13 7.96 7.96 0 0020 6.71a8.02 8.02 0 0110.66.64A7.93 7.93 0 0133 13"
      />
    </svg>
  </div>
);

export const DiscoverIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 780 500" height="28">
      <title>Discover</title>
      <g fillRule="evenodd">
        <path
          fill="#4D4D4D"
          d="M54.992 0H0v501h670.016C755.373 501 780 476.37 780 445.996V0H54.992z"
        />
        <path
          fill="#FFF"
          d="M327.152 161.893c8.837 0 16.248 1.784 25.268 6.09v22.751c-8.544-7.863-15.955-11.154-25.756-11.154-19.264 0-34.414 15.015-34.414 34.05 0 20.075 14.681 34.196 35.37 34.196 9.312 0 16.586-3.12 24.8-10.857v22.763c-9.341 4.14-16.911 5.776-25.756 5.776-31.278 0-55.582-22.596-55.582-51.737 0-28.826 24.951-51.878 56.07-51.878zm-97.113.627c11.546 0 22.11 3.72 30.943 10.994l-10.748 13.248c-5.35-5.646-10.41-8.028-16.564-8.028-8.853 0-15.3 4.745-15.3 10.989 0 5.354 3.619 8.188 15.944 12.482 23.365 8.044 30.29 15.176 30.29 30.926 0 19.193-14.976 32.553-36.32 32.553-15.63 0-26.994-5.795-36.458-18.872l13.268-12.03c4.73 8.61 12.622 13.222 22.42 13.222 9.163 0 15.947-5.952 15.947-13.984 0-4.164-2.055-7.734-6.158-10.258-2.066-1.195-6.158-2.977-14.2-5.647-19.291-6.538-25.91-13.527-25.91-27.185 0-16.225 14.214-28.41 32.846-28.41zm234.723 1.728h22.437l28.084 66.592 28.446-66.592h22.267l-45.494 101.686h-11.053l-44.687-101.686zm-397.348.152h30.15c33.312 0 56.534 20.382 56.534 49.641 0 14.59-7.104 28.696-19.118 38.057-10.108 7.901-21.626 11.445-37.574 11.445H67.414V164.4zm96.135 0h20.54v99.143h-20.54V164.4zm411.734 0h58.252v16.8H595.81v22.005h36.336v16.791H595.81v26.762h37.726v16.785h-58.252V164.4zm71.858 0h30.455c23.69 0 37.265 10.71 37.265 29.272 0 15.18-8.514 25.14-23.986 28.105l33.148 41.766h-25.26l-28.429-39.828h-2.678v39.828h-20.515V164.4zm20.515 15.616v30.025h6.002c13.117 0 20.069-5.362 20.069-15.328 0-9.648-6.954-14.697-19.745-14.697h-6.326zM87.94 181.199v65.559h5.512c13.273 0 21.656-2.394 28.11-7.88 7.103-5.955 11.376-15.465 11.376-24.98 0-9.499-4.273-18.725-11.376-24.681-6.785-5.78-14.837-8.018-28.11-8.018H87.94z"
        />
        <path
          fill="#F47216"
          d="M415.13 161.213c30.941 0 56.022 23.58 56.022 52.709v.033c0 29.13-25.081 52.742-56.021 52.742s-56.022-23.613-56.022-52.742v-.033c0-29.13 25.082-52.71 56.022-52.71zM779.983 288.36c-26.05 18.33-221.077 149.34-558.754 212.623h558.753V0z"
        />
      </g>
    </svg>
  );
};

export const AchIcon = () => {
  return (
    <div className="border border-gray-200">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="26"
        fill="none"
        viewBox="0 0 40 26"
      >
        <title>Ach</title>
        <g clipPath="url(#a)">
          <path fill="#fff" d="M0 0h40v26H0V0Z" />
          <path
            fill="#0AC500"
            d="M24.96 11.16a2 2 0 1 0 0 3.98 2 2 0 0 0 0-3.98Zm-4.9-2.92a1.62 1.62 0 1 0 0 3.24 1.62 1.62 0 0 0 0-3.24Zm0 6.58a1.62 1.62 0 1 0 0 3.24 1.62 1.62 0 0 0 0-3.24Zm-4.19-6.3a1.32 1.32 0 1 0 0-2.65 1.32 1.32 0 0 0 0 2.65Zm-3.62-4.46a1.09 1.09 0 1 0 0 2.17 1.09 1.09 0 0 0 0-2.17Zm3.62 16.37a1.32 1.32 0 1 0 0-2.65 1.32 1.32 0 0 0 0 2.65Zm-3.62-.36a1.09 1.09 0 1 0 0 2.17 1.09 1.09 0 0 0 0-2.17Z"
          />
          <path
            fill="#006EFF"
            d="M15.38 11.16a2 2 0 1 0 0 3.98 2 2 0 0 0 0-3.98Zm9.08-2.64a1.33 1.33 0 1 0 0-2.65 1.33 1.33 0 0 0 0 2.65Zm3.62-2.29a1.09 1.09 0 1 0 0-2.17 1.09 1.09 0 0 0 0 2.17Zm-3.62 14.2a1.33 1.33 0 1 0 0-2.65 1.33 1.33 0 0 0 0 2.65Zm3.62-.36a1.09 1.09 0 1 0 0 2.17 1.09 1.09 0 0 0 0-2.17Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h40v26H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const ApplePayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="28" fill="none" viewBox="0 0 40 26">
      <title>ApplePay</title>
      <path
        fill="#000"
        d="M36.42 0H3.58a69.25 69.25 0 0 0-.75 0c-.25.01-.5.03-.76.07a2.51 2.51 0 0 0-1.32.7A2.43 2.43 0 0 0 .07 2.1 5.14 5.14 0 0 0 0 3.22v19.91c.01.25.03.51.07.76a2.6 2.6 0 0 0 .68 1.35 2.39 2.39 0 0 0 1.32.69 4.98 4.98 0 0 0 1.1.07h34a5 5 0 0 0 .76-.07 2.5 2.5 0 0 0 1.32-.7 2.44 2.44 0 0 0 .68-1.34 5.13 5.13 0 0 0 .07-1.11V2.87a6.5 6.5 0 0 0-.07-.76 2.58 2.58 0 0 0-.68-1.35 2.4 2.4 0 0 0-1.32-.69 4.96 4.96 0 0 0-1.1-.07h-.41Z"
      />
      <path
        fill="#fff"
        d="M36.42.87h.73c.2 0 .42.02.62.06a1.67 1.67 0 0 1 .88.44 1.58 1.58 0 0 1 .44.89 4.38 4.38 0 0 1 .06.97v19.55a14.67 14.67 0 0 1-.06.96 1.7 1.7 0 0 1-.44.89 1.54 1.54 0 0 1-.87.44 4.27 4.27 0 0 1-.96.06H2.85a3.7 3.7 0 0 1-.63-.06 1.66 1.66 0 0 1-.87-.45 1.56 1.56 0 0 1-.44-.88 4.35 4.35 0 0 1-.06-.97V2.9c.01-.2.02-.42.06-.63.03-.18.08-.34.16-.49A1.56 1.56 0 0 1 2.22.93a4.2 4.2 0 0 1 .96-.06h33.24"
      />
      <path
        fill="#000"
        d="M10.92 8.61c.34-.43.57-1 .51-1.59a2.21 2.21 0 0 0-1.99 2.3c.56.04 1.12-.3 1.48-.7Zm.51.81c-.82-.05-1.52.46-1.9.46-.4 0-1-.43-1.64-.42-.84 0-1.62.48-2.05 1.24-.88 1.52-.23 3.76.62 5 .42.6.92 1.27 1.58 1.25.62-.02.86-.4 1.62-.4.75 0 .97.4 1.63.39.69-.01 1.11-.61 1.53-1.22.47-.7.67-1.37.68-1.4-.01-.02-1.32-.52-1.33-2.02-.01-1.26 1.03-1.85 1.07-1.9a2.34 2.34 0 0 0-1.81-.98Zm7.11-1.7a2.87 2.87 0 0 1 3.02 3c0 1.8-1.27 3.03-3.06 3.03h-1.97v3.12h-1.42V7.72h3.43Zm-2 4.83h1.62c1.24 0 1.94-.66 1.94-1.82 0-1.15-.7-1.81-1.93-1.81h-1.64v3.63Zm5.39 2.43c0-1.17.9-1.89 2.48-1.98l1.83-.1v-.52c0-.74-.5-1.18-1.34-1.18-.8 0-1.3.38-1.41.97h-1.3c.08-1.2 1.1-2.09 2.76-2.09 1.62 0 2.65.86 2.65 2.2v4.6h-1.31v-1.1h-.04a2.38 2.38 0 0 1-2.1 1.2c-1.3 0-2.22-.8-2.22-2Zm4.3-.6v-.53l-1.64.1c-.82.06-1.28.42-1.28.99 0 .58.48.96 1.22.96.96 0 1.7-.66 1.7-1.52Zm2.61 4.95v-1.11c.1.03.33.03.44.03.64 0 .98-.27 1.19-.96l.12-.4-2.41-6.69h1.48l1.7 5.43h.02l1.69-5.43h1.44l-2.5 7.02c-.57 1.62-1.23 2.14-2.61 2.14a5.3 5.3 0 0 1-.56-.03Z"
      />
    </svg>
  );
};

export const InteracCardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="28" fill="none" viewBox="0 0 40 26">
      <title>Interac</title>
      <path fill="#fdb913" d="M0 0h40v26H0z" />
      <g fill="#231f20" clip-path="url(#a)">
        <path d="M21.47 12.27v-4.5l1.17-.27v.59a1.69 1.69 0 0 1 1-.96.68.68 0 0 1 .33-.02v1.15a1.64 1.64 0 0 0-.61.12 1.07 1.07 0 0 0-.65 1.12v2.48zm-6.19 1.46a2.68 2.68 0 0 1-.2-1.3V10.2l-.6.14v-.92l.6-.14V8.27l1.26-.3v1.01l.88-.2v.91l-.88.21v2.28c0 1.04.27 1.24.27 1.24zm2.07-2.67a3.78 3.78 0 0 1 .36-1.83 2.09 2.09 0 0 1 1.46-1.04c1.37-.3 1.86.5 1.84 1.59v.58l-2.41.56v.04c0 .76.16 1.12.64 1.02a.66.66 0 0 0 .56-.67v-.18l1.14-.27v.2a1.9 1.9 0 0 1-1.7 1.86c-1.5.36-1.9-.55-1.9-1.86m1.88-2.03c-.4.1-.61.48-.62 1.13l1.21-.29v-.2c0-.48-.15-.74-.59-.64m8.98-.6c-.05-1.37.3-2.46 1.83-2.8.98-.22 1.35.03 1.53.27a1.56 1.56 0 0 1 .25 1v.07l-1.19.29v-.17c0-.52-.15-.72-.53-.62-.45.1-.63.54-.63 1.4v.39c0 .87.12 1.26.64 1.15.45-.09.51-.49.52-.83v-.23l1.2-.29v.19a2.01 2.01 0 0 1-1.73 2.11c-1.54.37-1.84-.53-1.9-1.93m-4.12 1.92c0-1.05.6-1.37 1.52-1.75.83-.35.85-.52.85-.81 0-.25-.11-.47-.52-.36a.62.62 0 0 0-.5.59 2.14 2.14 0 0 0 0 .23l-1.16.27a2.3 2.3 0 0 1 .08-.68c.18-.63.73-1.05 1.68-1.26 1.23-.29 1.64.25 1.64 1.1v1.98c0 .97.18 1.1.18 1.1l-1.14.28a2.47 2.47 0 0 1-.15-.41 1.56 1.56 0 0 1-1.1.83 1.04 1.04 0 0 1-1.38-1.11m2.36-1.43a4.3 4.3 0 0 1-.6.36.8.8 0 0 0-.55.76.4.4 0 0 0 .53.42.88.88 0 0 0 .62-.91zM8.75 16.89a.72.72 0 0 1-.6-1.12l.02-.01 1.7-.4v1.29h-.03c-.38.1-.84.2-.94.22a.71.71 0 0 1-.15.02m0 1.64a.72.72 0 0 1-.72-.72.71.71 0 0 1 .12-.39l.02-.01 1.7-.4v1.29h-.03c-.38.1-.84.2-.94.22a.76.76 0 0 1-.15.01m0 1.66a.72.72 0 0 1-.72-.72.71.71 0 0 1 .12-.4l1.72-.41v1.29h-.03l-.94.22a.75.75 0 0 1-.15.02m-.2-4.89V8.1l1.32-.32V15l-1.32.32zm3.28-.6a.81.81 0 0 0-1.62 0v6.73a2.2 2.2 0 0 0 2.2 2.2h2.29v-5.95a1.06 1.06 0 0 0-.47-.89l-2.05-1.39v3.16a.17.17 0 0 1-.35 0V14.7m1.09-5.04a1.77 1.77 0 0 0-1.17.9v-.5l-1.18.28v3.24a1.21 1.21 0 0 1 1.25.22v-2.1a.93.93 0 0 1 .6-.97c.27-.06.5.03.5.51v3.05l1.24-.3v-3.22c0-.78-.3-1.33-1.24-1.1m18.13-4.62a.77.77 0 1 1 .77-.78.77.77 0 0 1-.77.78m0-1.45a.67.67 0 1 0 .67.67.67.67 0 0 0-.67-.67" />
        <path d="M30.77 3.82h.33a.22.22 0 0 1 .24.24c0 .12-.06.21-.16.23.1.01.14.06.15.2v.19a.1.1 0 0 0 .05.08h-.17a.16.16 0 0 1-.03-.09V4.5c0-.1-.04-.15-.14-.15h-.12v.4h-.15zm.27.41a.14.14 0 0 0 .15-.15c0-.1-.05-.15-.15-.15h-.12v.3z" />
      </g>
    </svg>
  );
};

type Props = {
  paymentType: string;
  onlyPrimaryPart?: boolean;
};
export const PaymentMethodIcon = memo(({onlyPrimaryPart, paymentType}: Props) => {
  const iconType = onlyPrimaryPart ? getPrimaryPaymentMethodPart(paymentType) : paymentType;
  const paymentMethodMap: Record<string, ReactElement> = {
    amex: <AmexIcon />,
    visa: <VisaIcon />,
    mc: <MasterCardIcon />,
    discover: <DiscoverIcon />,
    ach: <AchIcon />,
    applepay: <ApplePayIcon />,
    // biome-ignore lint/style/useNamingConvention: Special convention for payment method icons
    interac_card: (
      <div className="flex items-center gap-x-2">
        <InteracCardIcon />
      </div>
    ),
    // biome-ignore lint/style/useNamingConvention: Special convention for payment method icons
    amex_applepay: (
      <div className="flex items-center gap-x-2">
        <ApplePayIcon /> <AmexIcon />
      </div>
    ),
    // biome-ignore lint/style/useNamingConvention: Special convention for payment method icons
    visa_applepay: (
      <div className="flex items-center gap-x-2">
        <ApplePayIcon /> <VisaIcon />
      </div>
    ),
    // biome-ignore lint/style/useNamingConvention: Special convention for payment method icons
    mc_applepay: (
      <div className="flex items-center gap-x-2">
        <ApplePayIcon /> <MasterCardIcon />
      </div>
    ),
    // biome-ignore lint/style/useNamingConvention: Special convention for payment method icons
    discover_applepay: (
      <div className="flex items-center gap-x-2">
        <ApplePayIcon /> <DiscoverIcon />
      </div>
    ),
  };

  const icon = paymentMethodMap[iconType] ?? (
    <span className="capitalize text-gray-500">{paymentType}</span>
  );
  return <div className="flex">{icon}</div>;
});
