import {t} from '@lingui/macro';
import type {MerchantRegistrationSource} from '@prisma/client';
import {StepperVerticalItem} from '@zentact/ui-tailwind';

export const MERCHANT_REGISTRATION_STEP_IDS = {
  step1: 'step1-create-account',
  step2: 'step2-terms-and-conditions',
  step3: 'step3-merchant-application',
  step4: 'step4-merchant-verification',
  step5: 'step5-merchant-tenant-review', // optional only when registration source is organization
  step6: 'step6-complete',
} as const;

export const merchantRegistrationStepTitles = (
  tenantName?: string
): Record<keyof typeof MERCHANT_REGISTRATION_STEP_IDS, string> => ({
  step1: t`Verify Email`,
  step2: t`Terms And Conditions`,
  step3: t`Merchant Application`,
  step4: t`Merchant Verification`,
  step5: t`${tenantName} Review`,
  step6: t`Complete! Next Steps`,
});

export const getMerchantRegistrationSteps = (
  registrationSource?: MerchantRegistrationSource,
  tenantName?: string
): StepperVerticalItem[] => [
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step1,
    title: merchantRegistrationStepTitles(tenantName).step1,
  },
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step2,
    title: merchantRegistrationStepTitles(tenantName).step2,
  },
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step3,
    title: merchantRegistrationStepTitles(tenantName).step3,
  },
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step4,
    title: merchantRegistrationStepTitles(tenantName).step4,
  },
  ...(registrationSource === 'ORGANIZATION'
    ? [
        {
          id: MERCHANT_REGISTRATION_STEP_IDS.step5,
          title: merchantRegistrationStepTitles(tenantName).step5,
        },
      ]
    : []),
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step6,
    title: merchantRegistrationStepTitles(tenantName).step6,
  },
];

export const NAME_SURNAME_REGEX = /^[a-z ,.'-]+$/i;
