import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {ChargebackListItemOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {
  CurrencyCode,
  LocaleCode,
  TableColumnSize,
  formatAmount,
  formatLocaleDate,
} from '@zentact/common';
import {ChargebackDisputeStatus, ChargebackType} from '@zentact/db';
import {
  DropDownMinimalMenuIcon,
  FlatFillColors,
  FlatPillWithDot,
  HighlightedText,
  TableCheckboxFilter,
  TableSearchFilter,
  TruncatedText,
  getTableMeta,
} from '@zentact/ui-tailwind';
import {CellCollapsedData} from '../../../table/cells/cell-collapsed-data/cell-collapsed-data';

export const displayChargebackTypeMap: {
  [_ in ChargebackType]: string;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  NOTIFICATION_OF_FRAUD: 'Notification Of Fraud',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  REQUEST_FOR_INFORMATION: 'Request For Information',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  CHARGEBACK: 'Chargeback',
};

export const chargebackDisputeStatusToColor: {
  [_ in ChargebackDisputeStatus]: FlatFillColors;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  NOT_APPLICABLE: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  UNRESPONDED: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  RESPONDED: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  EXPIRED: 'gray',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  UNDEFENDED: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  PENDING: 'yellow',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  ACCEPTED: 'green',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  LOST: 'red',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  WON: 'green',
};
export const chargebackDisputeStatusMap: {
  [_ in ChargebackDisputeStatus]: string;
} = {
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  NOT_APPLICABLE: 'Not applicable',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  UNRESPONDED: 'Not Responded',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  RESPONDED: 'Responded',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  EXPIRED: 'Expired',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  UNDEFENDED: 'Not Defended',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  PENDING: 'Pending',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  ACCEPTED: 'Accepted',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  LOST: 'Lost',
  // biome-ignore lint/style/useNamingConvention: DB Type conversion
  WON: 'Won',
};
const columnsHelper = createColumnHelper<ChargebackListItemOutput>();

type Props = {
  locale: LocaleCode;
  i18n: I18n;
  openDetailsPanel: (row: ChargebackListItemOutput) => void;
  showOrganizationColumn?: boolean;
};

export const getChargebackColumns = ({
  locale,
  i18n,
  openDetailsPanel,
  showOrganizationColumn,
}: Props) => {
  const renderDisputeType = (type: ChargebackType) => (
    <span className="py-1 text-xs font-medium">
      {i18n._(displayChargebackTypeMap[type]) ?? i18n._(type)}
    </span>
  );
  return [
    // @ts-ignore fix TS2589: Type instantiation is excessively deep and possibly infinite. error
    columnsHelper.accessor('createdAt', {
      cell: props => formatLocaleDate(props.getValue(), 'shortWithTime'),
      header: () => i18n._('Dispute Date'),
      meta: {
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.pspReferenceId as string;
        const textContent = props.row.original.pspReferenceId || '';

        return <HighlightedText text={textContent} highlight={searchString} />;
      },
      id: 'pspReferenceId',
      header: () => i18n._('Dispute ID'),
      meta: {
        filter: {
          filterId: 'pspReferenceId',
          renderFilter: ({...filterProps}) => <TableSearchFilter {...filterProps} />,
        },
        visibleAt: 'md',
      },
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.paymentPspReferenceId as string;
        const textContent = props.row.original.payment.pspReferenceId || '';

        return <HighlightedText text={textContent} highlight={searchString} />;
      },
      id: 'paymentPspReferenceId',
      header: () => i18n._('Payment ID'),
      meta: {
        filter: {
          filterId: 'paymentPspReferenceId',
          renderFilter: ({...filterProps}) => <TableSearchFilter {...filterProps} />,
        },
        visibleAt: '2xl',
      },
      size: TableColumnSize.L,
    }),
    columnsHelper.display({
      id: 'merchantName',
      header: () => i18n._('Merchant Account'),
      cell: props => {
        const {breakpoints} = getTableMeta(props.table);
        const merchantName = props.row.original.merchantAccount.businessName;
        const organizationName = props.row.original.organization.name;

        return (
          <CellCollapsedData
            primary={<TruncatedText text={merchantName} />}
            secondary={<TruncatedText text={organizationName} />}
            hideSecondary={!(showOrganizationColumn && breakpoints.sm)}
          />
        );
      },
      meta: {
        collapseAt: 'sm',
      },
    }),
    columnsHelper.accessor(row => formatAmount(row.amount, locale, row.currency as CurrencyCode), {
      id: 'amount',
      header: () => i18n._('Amount'),
      meta: {
        sort: {
          isSortable: true,
        },
        visibleAt: '2xl',
      },
      size: TableColumnSize.XS,
    }),
    columnsHelper.display({
      id: 'disputeStatus',
      header: props =>
        getTableMeta(props.table).breakpoints['2xl'] ? 'Dispute Status' : 'Dispute',
      cell: props => {
        const {breakpoints} = getTableMeta(props.table);
        const {disputeStatus, amount, currency} = props.row.original;

        return (
          <CellCollapsedData
            primary={
              <FlatPillWithDot
                color={chargebackDisputeStatusToColor[disputeStatus] || 'blue'}
                label={chargebackDisputeStatusMap[disputeStatus] ?? disputeStatus}
              />
            }
            secondary={renderDisputeType(props.row.original.type)}
            tertiary={formatAmount(amount, locale, currency as CurrencyCode)}
            hideTertiary={breakpoints['2xl']}
          />
        );
      },

      meta: {
        filter: {
          filterId: 'disputeStatus',
          renderFilter: ({onChange, filterId, filterValues}) => (
            <TableCheckboxFilter
              filterValues={filterValues}
              filterId={filterId}
              onChange={onChange}
              elements={(
                Object.keys(chargebackDisputeStatusToColor) as Array<
                  keyof typeof chargebackDisputeStatusToColor
                >
              ).map(status => ({
                element: (
                  <FlatPillWithDot
                    color={chargebackDisputeStatusToColor[status] || 'blue'}
                    label={chargebackDisputeStatusMap[status] ?? status}
                  />
                ),
                key: status,
              }))}
            />
          ),
        },
      },
      size: 180,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <DropDownMinimalMenuIcon
            items={[
              {name: i18n._('View Details'), onClick: () => openDetailsPanel(props.row.original)},
            ]}
          />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
