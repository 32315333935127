import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {ChargebackListOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {PaymentsListOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CSV_EXPORT_FULL_DATE_FORMAT,
  CurrencyCode,
  LocaleCode,
  capitalizeCustomAttribute,
  formatAmountWithoutCurrency,
} from '@zentact/common';
import {
  ExportToCsv,
  chargebackDisputeStatusMap,
  displayChargebackTypeMap,
  displayPaymentStatusMap,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';

export const exportToCsvDisputes = (chargebackList: ChargebackListOutput['rows'], i18n: I18n) => {
  const exporter = new ExportToCsv({
    filename: `disputes-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!chargebackList.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    chargebackList.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Dispute Transaction Id`]: row.pspReferenceId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Transaction Id`]: row.payment.pspReferenceId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Reference Id`]: row.payment.reference,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Amount`]: formatAmountWithoutCurrency(
        row.amount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Type`]: displayChargebackTypeMap[row.type],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Status`]: chargebackDisputeStatusMap[row.disputeStatus],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Reason`]: row.reason,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Reason Code`]: row.reasonCode,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Fee`]: row.payment.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.payment.authorizedAmount - row.payment.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Merchant Amount`]: row.payment.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.payment.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method`]: row.paymentMethod,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method Variant`]: row.payment.paymentMethodVariant,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Source`]: row.payment.source,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Card ending`]: row.payment.cardSummary ? `*${row.payment.cardSummary}` : '',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Created At`]: DateTime.fromISO(row.createdAt).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Updated At`]: DateTime.fromISO(row.updatedAt).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Defense Ends At`]: row.defensePeriodEndsAt
        ? DateTime.fromISO(row.defensePeriodEndsAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT)
        : '-',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Created At`]: DateTime.fromISO(row.payment.createdAt).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
    }))
  );
};

export const exportToCsvPayments = (fullPaymentList: PaymentsListOutput['rows'], i18n: I18n) => {
  const exporter = new ExportToCsv({
    filename: `payments-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullPaymentList.length) {
    throw new Error(t`No data to export`);
  }
  exporter.generateCsv(
    fullPaymentList.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`PSP Transaction Id`]: row.pspReferenceId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Reference Id`]: row.reference,
      ...(row.checkout
        ? Object.fromEntries(
            row.checkout.checkoutAttributes.map(checkoutAttribute => [
              t`${capitalizeCustomAttribute(checkoutAttribute.name)}`,
              checkoutAttribute.value,
            ])
          )
        : {}),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Authorized Amount`]: formatAmountWithoutCurrency(
        row.authorizedAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Refunded Amount`]: formatAmountWithoutCurrency(
        row.refundedAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Fee`]: row.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.authorizedAmount - row.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Merchant Amount`]: row.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Payment Method`]: row.paymentMethod,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Payment Method Variant`]: row.paymentMethodVariant,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Card ending`]: row.cardSummary ? `*${row.cardSummary}` : '',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Status`]: displayPaymentStatusMap[row.status],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Updated At`]: DateTime.fromISO(row.updatedAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Created At`]: DateTime.fromISO(row.createdAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys used in CSV export
      [t`Source`]: row.source,
    }))
  );
};
