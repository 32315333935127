import {useAuth0} from '@auth0/auth0-react';
import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {OrganizationUserItem} from '@zentact/api/src/trpc/routers/usersRouter';
import {useCallback, useState} from 'react';
import {DropDownMinimalMenuIcon} from '../../../../dropdowns';
import {useNotification} from '../../../../hooks';
import {AlertOverlayWithConfirmation} from '../../../../overlays';
import {cn} from '../../../../utils';

type Props = {
  userRow: OrganizationUserItem;
  refetchActiveUsersList: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  mode: 'organizationUser' | 'tenantUser';
  openUserEditPanel: (row: OrganizationUserItem) => void;
};

const statusToItemsMap = (
  action: (action: 'deleteUser' | 'editUser') => void,
  disableDelete: boolean,
  i18n: I18n
): Record<string, {name: string; onClick: () => void; itemClassName?: string}[]> => ({
  // biome-ignore lint/style/useNamingConvention: UI Mapping
  Active: [
    {name: i18n._('Edit User'), onClick: () => action('editUser')},
    {
      name: i18n._('Delete User'),
      onClick: () => action('deleteUser'),
      itemClassName: cn('text-red-500', disableDelete && 'hidden'),
    },
  ],
});

export const UserActions = ({
  userRow,
  refetchActiveUsersList,
  trpc,
  mode,
  openUserEditPanel,
}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const {user: contextUser} = useAuth0();
  const {i18n} = useLingui();

  const isContextUser = userRow.id === contextUser?.sub;

  const deleteUserEndpoint =
    mode === 'organizationUser'
      ? trpc.users.deleteOrganizationUserAccount
      : trpc.users.deleteTenantUserAccount;

  const deleteUserMutation = deleteUserEndpoint.useMutation({
    onSuccess: () => {
      refetchActiveUsersList();
      showSuccessNotification(
        i18n._('User deleted'),
        i18n._('You have successfully deleted { name }.', {name: userRow.name})
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const handleAction = useCallback(
    (action: string) => {
      if (action === 'editUser') {
        openUserEditPanel(userRow);
      }

      if (action === 'deleteUser') {
        setDeleteConfirmationOpen(true);
      }
    },
    [openUserEditPanel, userRow]
  );

  const handleDeleteUser = useCallback(() => {
    deleteUserMutation.mutate({
      userId: userRow.id,
    });
  }, [userRow.id]);

  const menuItems = statusToItemsMap(handleAction, isContextUser, i18n)[userRow.status];
  if (!menuItems) return null;

  return (
    <>
      <DropDownMinimalMenuIcon items={menuItems} />
      {deleteConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deleteConfirmationOpen}
          setOpen={setDeleteConfirmationOpen}
          handleAction={handleDeleteUser}
          localeText={{
            title: i18n._('Delete user'),
            description: i18n._('Are you sure you want to delete  { name }?', {
              name: userRow.name,
            }),
            confirm: i18n._('Delete'),
            cancel: i18n._('Cancel'),
          }}
        />
      )}
    </>
  );
};
